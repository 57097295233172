.body {
  background-color: white;
  max-width: 1280px;
}

.header {
  display: flex;
  flex-direction: column;
  width:100%;
  border-bottom: solid 1px lightgray;
  position:sticky;
  top:0px;
  background-color: white;
  z-index: 10;

}

.header_bottom {
  font-family: Montserrat !important;
}
.header_bottom:hover {
  box-shadow: 2px 2px 8px 2px silver !important;
}

.categoryselection:hover {
  background-color: rgb(0, 166, 153) !important;

}

.fallback {
  min-height: 60vh;
}


@media only screen and (max-width: 780px) {
  .fallback {

    min-height: 120;


  }
}
