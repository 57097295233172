.searchresults_filtercontainer {
    display:flex;
    flex-direction: row;
    border-bottom: solid 3px lightgray;
    box-shadow: silver;
    position:sticky;
    background-color: white;
   
}



.searchresults {
    display:flex;
    flex-direction: row;

}

.searchresults__items {
    width:60%;
    justify-content: center;
}

.filterbuttons:hover {
    border-right: solid 2px #FF5A5F !important;
   
}

@media only screen and (max-width: 780px) {
    .searchresults__items {

        width: 90%;
        justify-content: center;
    }
   
  }