.blogpostsfordesktop {

  background-color: white;
  width:100%
}
.mainposts {
  display:flex;
  flex-direction: row;
  margin-top: 1%;
  margin-bottom:3%;

}



.mainposts__left {
  display: flex;
  flex-direction: column;


}

.mainposts__left:hover {

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.mainposts__right {
  display: flex;
  flex-direction: column;
}

.mainposts__right:hover {

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}
.allposts {
  display: flex;
  flex-direction: row;
  margin-left:auto;
  margin-right:auto;
  justify-content: center;
  width:80%;
}
.allpostsformobile {
  display: flex;
  flex-direction: column;
  margin-left:auto;
  margin-right:auto;
  justify-content: center;
  width:80%;

}
.singleblogitem {
  border-radius: 10px;
  background-clip: padding-box;
  background-color: #F8F6F6;
  margin-right:auto;
  justify-content: center;
  width:65%;
  display:flex;
  flex-direction: column ;
  margin-top:3%;
  margin-bottom:3%;
  border:2px solid #F8F6F6;
  outline:2px solid #F8F6F6;
  color: black;
  text-decoration: none;
}

.singleblogitem:hover {

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.singleblogitem:hover h3 {

color : #FF5A5F;

}




.singleblogitemformobile {
  border-radius: 10px;
  width:90%;
  display:flex;
  flex-direction: column ;
  margin-bottom:1%;
  margin-top: 1%;
  border:2px solid #F8F6F6;
  outline:2px solid #F8F6F6;
}



.blogpostsformobile {
  background-color: white;
}




