
      
  .pricing {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Poppins';
  }

  .pricing__planitem {


    text-align: left;
    margin-top:  10px;
    width:30%;
    border: 1px solid lightgray;
    border-radius: 10px;


  }

  .pricing__planitem_content {

    min-height: 35vh;
    margin-left:10%;
    margin-right: 10%;



  }


  .pricing_planitem h1{


    font-size: 16px;
    
  }

.subscribe_button {

  color:white;
  border-radius: 10px;

  width:100%;
  margin-left:auto;
  margin-right:auto;

  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  font-family: 'Poppins';
  border:0.5px solid white;
  cursor: pointer;
  font-weight: bold;


  
}


.subscribe_button:hover {

  background-color: aliceblue !important;
  color:black;
  border:0.5px solid black;


}

  @media only screen and (max-width: 840px) {


      
  .pricing  {
  
     flex-direction: column;
     width:100%;
     margin:0%;
    
  
    }

    
  .pricing__planitem {

    margin:auto;
    justify-content: center;
    width:90%;
    border: 1px solid lightgray;
    border-radius: 10px;


  }



  }
  