.services_container {
    display:flex;
    flex-direction: row;
    width:100%;
    border-bottom:2px solid #FF5A5F;
    margin-top:4%;
    max-height: 300px;
}

.services_avatar {
    margin-right:auto;
    margin-bottom:auto;
    margin-top:auto;
    justify-content: center;
    width:200px;
    height:150px;
    object-fit: contain;
    background-position: center center;
    background-size: 200px 150px !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    border-radius: 6px;
    justify-content: center;
    
}

.services_right_describtion {
    display:flex;
    flex-direction: column;
    margin-right:auto;
    margin-left:2%;

}

.services_right {
    width:70%;
    
}

.services_right_price {
    margin-right:0%;
}

.services_right_describtion h4 {
    margin-top: 0%;
    margin-bottom:0%;
}

@media only screen and (max-width: 780px) {
    .services_container {
        display:flex;
        flex-direction: column !important;
        width: 100%;
        max-height: 600px;
    }
   .services_avatar {
       width: 95%;
       height: 200px;
       object-fit: contain;
       margin-right: auto;
       margin-left:auto;
       justify-content: center;
   }
   .services_right {
    width:95%;
    
}
   .services_right_title {
       font-weight: bolder !important;
   }
   .services_price {
       margin-left: auto;
   }
   .services_right_describtion h4 {
       margin-top: 1%;
   }
  }