.headertop_main {
    display: flex;
    flex-direction: row;
    width:100%;
    
 

 
}

.headertop_logo {
    background-image: url('./assets/logo2-.png');
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    object-fit: contain;
    margin-right:auto;
    width:10%;
    margin-left:5%;
    cursor: pointer;
    
}

.headertop_logo_mobile {
    background-image: url('./assets/logo1.png');
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    object-fit: contain;
    margin-right:auto;
    width:10%;
    margin-left:5%;
    cursor: pointer;

}

.headertop_desktopmenu {
    display: flex;
    flex-direction: row;
    margin-left:auto;
    margin-right:5%;
    margin-top:1%;
    margin-bottom:1%;
    justify-content: center;
    color:black


 
}
.whatsapp_button{
    background-color: transparent ;
    display:flex;
    flex-direction: row;
    margin-right: 2%;
    object-fit: contain;
    width:100%;
    cursor: pointer !important;
    border-radius:10px ;
    color:#25D366;
    font-family: Montserrat !important;
    font-weight: bold !important;
}

.whatsapp_button:hover{
    background-color: #25D366 ;
    color:white;



}
.header_sign-in-up {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    padding:10%;
    justify-content: center;
    border: solid 2px white;
    white-space: nowrap;
    border-radius: 10px;
    object-fit: contain;
    cursor: pointer;

}
.header_avatar {
    margin-top:auto;
    margin-bottom:auto;
    justify-content: center;
    object-fit: contain;
    border: solid 1px #F42B2B ;
    border: solid 2px #00a366 ;

}
.header_avatar:hover{

    border: solid 2px #F42B2B ;
   

}



.header_sign-in-up:hover {
    color: #F42B2B !important;
}
@media only screen and (max-width: 780px) {
    .headertop_logo {
        width:100px;
        height:75px;

      
    }
    .header_sign-in-up {  

        margin-top: 2%;

     }

     .headertop_main { 

        margin-top: 2%;


     }
}
/* #00CC80 #4E9BB2   #f5f5f5  */ 