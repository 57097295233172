@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Montserrat','Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.body {
  background-color: white;
  max-width: 1280px;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width:100%;
  border-bottom: solid 1px lightgray;
  position:-webkit-sticky;
  position:sticky;
  top:0px;
  background-color: white;
  z-index: 10;

}

.header_bottom {
  font-family: Montserrat !important;
}
.header_bottom:hover {
  box-shadow: 2px 2px 8px 2px silver !important;
}

.categoryselection:hover {
  background-color: rgb(0, 166, 153) !important;

}

.fallback {
  min-height: 60vh;
}


@media only screen and (max-width: 780px) {
  .fallback {

    min-height: 120;


  }
}

.headertop_main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width:100%;
    
 

 
}

.headertop_logo {
    background-image: url(/static/media/logo2-.0003e3b5.png);
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    object-fit: contain;
    margin-right:auto;
    width:10%;
    margin-left:5%;
    cursor: pointer;
    
}

.headertop_logo_mobile {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAN0AAAEOCAMAAAA6x+IdAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABmUExURf9VVf9aX/9aX/9SV/9UWf9WW/9XXP9ZXv9aX/9dYf9eY/9ydv92ev94fP+jpv+1t/+4u/+6u/+6vf+8vf/Ky//LzP/a3P/c3v/f4f/h4f/l5f/q6//r7P/v8P/39//9/f/+/v///5icY7gAAAADdFJOUwzr/hTjyIYAAAAJcEhZcwAAFuoAABbqAeWOQxAAAAHhSURBVHhe7d25bhtREERR0zYpet83eZH1/z9poDEhBQ9AYVhFnJN2B33Tl7wn1+3p8Yqp66Wul7pe6nqp66Wul7pe6nqp66Wul7pe6nqp66Wul7pe6nqp66Wul7pe6haH026WcaLVdYdX376e8v3t82Uj0Oq6/Zv7094/WzYCqRvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+riqBvq4qgb6uKoG+rinF/3cf/yYl4sxz3k/Lo/P39dyu9P//kG+fy6S/py1XWf1a2hbnPqVlG3OXWrqNuculXUbU7dKuo2p24VdZtTt8r+9d393zCP92p0c/xw+yPM7bvHqjve7PMcltsesr6ukbpe6nqp66Wul7pe6nqp66Wul7pe6nqp66Wul7pe6nqp66Wul7pe6nqp66Wul7pe6nqp66Wul7pe6nqp66Wul7pe6nqp63Xldbvrtdv9A+icvtUWslKNAAAAAElFTkSuQmCC);
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    object-fit: contain;
    margin-right:auto;
    width:10%;
    margin-left:5%;
    cursor: pointer;

}

.headertop_desktopmenu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-left:auto;
    margin-right:5%;
    margin-top:1%;
    margin-bottom:1%;
    -webkit-justify-content: center;
            justify-content: center;
    color:black


 
}
.whatsapp_button{
    background-color: transparent ;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-right: 2%;
    object-fit: contain;
    width:100%;
    cursor: pointer !important;
    border-radius:10px ;
    color:#25D366;
    font-family: Montserrat !important;
    font-weight: bold !important;
}

.whatsapp_button:hover{
    background-color: #25D366 ;
    color:white;



}
.header_sign-in-up {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    padding:10%;
    -webkit-justify-content: center;
            justify-content: center;
    border: solid 2px white;
    white-space: nowrap;
    border-radius: 10px;
    object-fit: contain;
    cursor: pointer;

}
.header_avatar {
    margin-top:auto;
    margin-bottom:auto;
    -webkit-justify-content: center;
            justify-content: center;
    object-fit: contain;
    border: solid 1px #F42B2B ;
    border: solid 2px #00a366 ;

}
.header_avatar:hover{

    border: solid 2px #F42B2B ;
   

}



.header_sign-in-up:hover {
    color: #F42B2B !important;
}
@media only screen and (max-width: 780px) {
    .headertop_logo {
        width:100px;
        height:75px;

      
    }
    .header_sign-in-up {  

        margin-top: 2%;

     }

     .headertop_main { 

        margin-top: 2%;


     }
}
/* #00CC80 #4E9BB2   #f5f5f5  */ 

      
  .pricing {

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    font-family: 'Poppins';
  }

  .pricing__planitem {


    text-align: left;
    margin-top:  10px;
    width:30%;
    border: 1px solid lightgray;
    border-radius: 10px;


  }

  .pricing__planitem_content {

    min-height: 35vh;
    margin-left:10%;
    margin-right: 10%;



  }


  .pricing_planitem h1{


    font-size: 16px;
    
  }

.subscribe_button {

  color:white;
  border-radius: 10px;

  width:100%;
  margin-left:auto;
  margin-right:auto;

  -webkit-justify-content: center;

          justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  font-family: 'Poppins';
  border:0.5px solid white;
  cursor: pointer;
  font-weight: bold;


  
}


.subscribe_button:hover {

  background-color: aliceblue !important;
  color:black;
  border:0.5px solid black;


}

  @media only screen and (max-width: 840px) {


      
  .pricing  {
  
     -webkit-flex-direction: column;
  
             flex-direction: column;
     width:100%;
     margin:0%;
    
  
    }

    
  .pricing__planitem {

    margin:auto;
    -webkit-justify-content: center;
            justify-content: center;
    width:90%;
    border: 1px solid lightgray;
    border-radius: 10px;


  }



  }
  
.infoblog_main {

    font-family: "Poppins", sans-serif ;
    width:80%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin:auto;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: justify;
    text-justify: inter-word;
  }

  .infoblog_main ul {
    font-family: "Poppins", sans-serif ;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    margin-left:5%;
  
  
  }
 
  
  .infoblog_main h1 {
      margin-left:4%;
      margin-top:1%;
      width:90%;
      font-size: 30px !important;
  
  }
  
  .infoblog_main h2 {
     margin-left:4%;
      font-size: 18px;
      line-height: 32px;
      font-weight: 700;
      }
      .infoblog_main h3 {
        margin-left:4%;
         font-size: 18px;
         line-height: 32px;
         font-weight: 700;
         }
.infoblog_main p {
  
      margin-left:4%;
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      text-align:justify;
      
      }
  
  
      .blogpost-left-col img{
  
        display: -webkit-flex;
  
        display: flex;
        width:95% !important;
        margin-left: auto;
        margin-right: auto;
        -webkit-justify-content: center;
                justify-content: center;
        
      }
  
          
      .blogpost-left-col h3{
  
        text-align:justify !important ;
    
      }
  
      .blogpost-left-col table{
  
        overflow-x:auto;
        display: -webkit-flex;
        display: flex;
        width:90%;
    
      }
  
  
  
    .firstphoto {
      width:100% !important;
      max-height: 400px;
      margin-left: 4%;
      margin-top: 0%;
      margin-bottom: 2%;
      width:90%;
      object-fit: cover;
      border-radius: 8px;
      background-color: lightgray;
      min-height: 300px;
    }
      
    .inlinephoto {
      display: -webkit-flex;
      display: flex;
      width:60%;
      margin-left: auto;
      margin-right: auto;
      -webkit-justify-content: center;
              justify-content: center;
    }
    
    
     
  .blogpost-right-col {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      width:30%;
      margin-left: auto;
      border:solid 1px blue;
  
  }
  
  
  
  .blog_main_content a {
      -webkit-text-decoration: solid;
              text-decoration: solid;
      color: #FF5A5F; /*  */
    }
  
  
  .blog_main_content ul {
      list-style: none; /* Remove default bullets */
    }
    
  .blog_main_content  ul li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #FF5A5F; /* Change the color */
      font-weight: bolder; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  
  
  
  
    .blog_main_content    blockquote{
      font-size: 1.4em;
      width:80%;
      margin-top:0% !important;
      margin-bottom:0%;
      margin-left:auto;
      margin-right:auto;
      -webkit-justify-content: center;
              justify-content: center;
      font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-style:normal;
      color: #00A699;
      padding:1.2em 30px 1.2em 75px;
      border-left:8px solid #FF5A5F ;
      border-radius: 8px;
      line-height:1.6;
      position: relative;
      background:#EDEDED;
    }
    
    .blog_main_content  blockquote::before{
      font-family:Montserrat;
      content: "\201C";
      color:#FF5A5F;
      font-size:4em;
      position: absolute;
      left: 10px;
      top:-10px;
    }
    
    .blog_main_content   blockquote::after{
      content: '';
    }
    
    .blog_main_content   blockquote span{
      display:block;
      color:#333333;
      font-style: normal;
      font-weight: bold;
      margin-top:1em;
    }
  
    .fallbackimage {
      background-color: antiquewhite;
      width:95%;
      min-height: 300px;
  
    }
  
    @media only screen and (max-width: 840px) {
  
      .blogpost-right-col {
        display: none !important;
      }
  
        
    .blog_main_content  {
    
       -webkit-flex-direction: column;
    
               flex-direction: column;
       width:100%;
       margin:0%;
      
    
      }
  
  
      .blogpost-left-col {
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        width:94% !important;
        margin-left:2%;
        padding:0%;
        margin-right: 0%;
        -webkit-justify-content: center;
                justify-content: center;
  
      }
  
      .blogpost-left-col h1{
        width:90%;
        
      }
      .blogpost-left-col p{
        width:100%;
        margin:0%;
        padding:0%;
        
      }
  
      .blogpost-left-col h2{
        width:100%;
        
      }
  
      .blogpost-left-col img{
        width:90%;
        
      }
      
      .blog_main_content blockquote {
        width:70%;
  
  
      }
  
      .blog_main_content table{
  
        overflow-x:auto;
        width:100%;
  
      }
  
  
    }
    
.searchresults_filtercontainer {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    border-bottom: solid 3px lightgray;
    box-shadow: silver;
    position:-webkit-sticky;
    position:sticky;
    background-color: white;
   
}



.searchresults {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;

}

.searchresults__items {
    width:60%;
    -webkit-justify-content: center;
            justify-content: center;
}

.filterbuttons:hover {
    border-right: solid 2px #FF5A5F !important;
   
}

@media only screen and (max-width: 780px) {
    .searchresults__items {

        width: 90%;
        -webkit-justify-content: center;
                justify-content: center;
    }
   
  }
.services_container {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width:100%;
    border-bottom:2px solid #FF5A5F;
    margin-top:4%;
    max-height: 300px;
}

.services_avatar {
    margin-right:auto;
    margin-bottom:auto;
    margin-top:auto;
    -webkit-justify-content: center;
            justify-content: center;
    width:200px;
    height:150px;
    object-fit: contain;
    background-position: center center;
    background-size: 200px 150px !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    border-radius: 6px;
    justify-content: center;
    
}

.services_right_describtion {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right:auto;
    margin-left:2%;

}

.services_right {
    width:70%;
    
}

.services_right_price {
    margin-right:0%;
}

.services_right_describtion h4 {
    margin-top: 0%;
    margin-bottom:0%;
}

@media only screen and (max-width: 780px) {
    .services_container {
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
        width: 100%;
        max-height: 600px;
    }
   .services_avatar {
       width: 95%;
       height: 200px;
       object-fit: contain;
       margin-right: auto;
       margin-left:auto;
       -webkit-justify-content: center;
               justify-content: center;
   }
   .services_right {
    width:95%;
    
}
   .services_right_title {
       font-weight: bolder !important;
   }
   .services_price {
       margin-left: auto;
   }
   .services_right_describtion h4 {
       margin-top: 1%;
   }
  }
.blogpostsfordesktop {

  background-color: white;
  width:100%
}
.mainposts {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 1%;
  margin-bottom:3%;

}



.mainposts__left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;


}

.mainposts__left:hover {

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.mainposts__right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.mainposts__right:hover {

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}
.allposts {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left:auto;
  margin-right:auto;
  -webkit-justify-content: center;
          justify-content: center;
  width:80%;
}
.allpostsformobile {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left:auto;
  margin-right:auto;
  -webkit-justify-content: center;
          justify-content: center;
  width:80%;

}
.singleblogitem {
  border-radius: 10px;
  background-clip: padding-box;
  background-color: #F8F6F6;
  margin-right:auto;
  -webkit-justify-content: center;
          justify-content: center;
  width:65%;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column ;
          flex-direction: column ;
  margin-top:3%;
  margin-bottom:3%;
  border:2px solid #F8F6F6;
  outline:2px solid #F8F6F6;
  color: black;
  text-decoration: none;
}

.singleblogitem:hover {

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.singleblogitem:hover h3 {

color : #FF5A5F;

}




.singleblogitemformobile {
  border-radius: 10px;
  width:90%;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column ;
          flex-direction: column ;
  margin-bottom:1%;
  margin-top: 1%;
  border:2px solid #F8F6F6;
  outline:2px solid #F8F6F6;
}



.blogpostsformobile {
  background-color: white;
}





