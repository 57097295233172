.infoblog_main {

    font-family: "Poppins", sans-serif ;
    width:80%;
    display:flex;
    flex-direction: column;
    margin:auto;
    justify-content: center;
    text-align: justify;
    text-justify: inter-word;
  }

  .infoblog_main ul {
    font-family: "Poppins", sans-serif ;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    margin-left:5%;
  
  
  }
 
  
  .infoblog_main h1 {
      margin-left:4%;
      margin-top:1%;
      width:90%;
      font-size: 30px !important;
  
  }
  
  .infoblog_main h2 {
     margin-left:4%;
      font-size: 18px;
      line-height: 32px;
      font-weight: 700;
      }
      .infoblog_main h3 {
        margin-left:4%;
         font-size: 18px;
         line-height: 32px;
         font-weight: 700;
         }
.infoblog_main p {
  
      margin-left:4%;
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      text-align:justify;
      
      }
  
  
      .blogpost-left-col img{
  
        display: flex;
        width:95% !important;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        
      }
  
          
      .blogpost-left-col h3{
  
        text-align:justify !important ;
    
      }
  
      .blogpost-left-col table{
  
        overflow-x:auto;
        display: flex;
        width:90%;
    
      }
  
  
  
    .firstphoto {
      width:100% !important;
      max-height: 400px;
      margin-left: 4%;
      margin-top: 0%;
      margin-bottom: 2%;
      width:90%;
      object-fit: cover;
      border-radius: 8px;
      background-color: lightgray;
      min-height: 300px;
    }
      
    .inlinephoto {
      display: flex;
      width:60%;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
    
    
     
  .blogpost-right-col {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      width:30%;
      margin-left: auto;
      border:solid 1px blue;
  
  }
  
  
  
  .blog_main_content a {
      text-decoration: solid;
      color: #FF5A5F; /*  */
    }
  
  
  .blog_main_content ul {
      list-style: none; /* Remove default bullets */
    }
    
  .blog_main_content  ul li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #FF5A5F; /* Change the color */
      font-weight: bolder; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  
  
  
  
    .blog_main_content    blockquote{
      font-size: 1.4em;
      width:80%;
      margin-top:0% !important;
      margin-bottom:0%;
      margin-left:auto;
      margin-right:auto;
      justify-content: center;
      font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-style:normal;
      color: #00A699;
      padding:1.2em 30px 1.2em 75px;
      border-left:8px solid #FF5A5F ;
      border-radius: 8px;
      line-height:1.6;
      position: relative;
      background:#EDEDED;
    }
    
    .blog_main_content  blockquote::before{
      font-family:Montserrat;
      content: "\201C";
      color:#FF5A5F;
      font-size:4em;
      position: absolute;
      left: 10px;
      top:-10px;
    }
    
    .blog_main_content   blockquote::after{
      content: '';
    }
    
    .blog_main_content   blockquote span{
      display:block;
      color:#333333;
      font-style: normal;
      font-weight: bold;
      margin-top:1em;
    }
  
    .fallbackimage {
      background-color: antiquewhite;
      width:95%;
      min-height: 300px;
  
    }
  
    @media only screen and (max-width: 840px) {
  
      .blogpost-right-col {
        display: none !important;
      }
  
        
    .blog_main_content  {
    
       flex-direction: column;
       width:100%;
       margin:0%;
      
    
      }
  
  
      .blogpost-left-col {
        display:flex;
        flex-direction: column;
        width:94% !important;
        margin-left:2%;
        padding:0%;
        margin-right: 0%;
        justify-content: center;
  
      }
  
      .blogpost-left-col h1{
        width:90%;
        
      }
      .blogpost-left-col p{
        width:100%;
        margin:0%;
        padding:0%;
        
      }
  
      .blogpost-left-col h2{
        width:100%;
        
      }
  
      .blogpost-left-col img{
        width:90%;
        
      }
      
      .blog_main_content blockquote {
        width:70%;
  
  
      }
  
      .blog_main_content table{
  
        overflow-x:auto;
        width:100%;
  
      }
  
  
    }
    